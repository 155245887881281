import React from "react";
import "./usecases.css";

const useCasesTopics = [
  "High Energy Costs",
  "Unplanned Downtime",
  "Grid Inefficiencies",
];

const withoutArdraContent = [
  "Struggle with high energy bills due to lack of optimization.",
  "Experience frequent equipment failures and downtime.",
  "Face inefficiencies and reliability issues in the grid.",
];

const withArdraContent = [
  "Reduce costs and enhance efficiency with real-time analytics.",
  "Prevent failures with advanced predictive maintenance.",
  "Improve grid reliability and efficiency.",
];

const UseCases = () => {
  return (
    <div className="usecases-section">
      <h2 className="usecases-main-title">Challenges and Solutions</h2>
      <div className="usecases-container">
        {useCasesTopics.map((topic, index) => (
          <div className="usecases-row" key={index}>
            <div className="usecases-column">
              <div className="usecase-content topic">
                <h5 className="usecase-topic">{topic}</h5>
              </div>
            </div>
            <div className="usecases-column">
              <div className="usecase-content without-ardra">
                <p className="usecase-description">
                  {withoutArdraContent[index]}
                </p>
              </div>
            </div>
            <div className="usecases-column">
              <div className="usecase-content with-ardra">
                <p className="usecase-description">{withArdraContent[index]}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseCases;
