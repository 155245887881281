import React, { useRef, useEffect } from "react";
import Team from "../Component/Team";
import "./AboutUs.css";
import { useLocation } from "react-router-dom";
import dataimage from "../Assist/aboutassist/about2.png";
import { Helmet } from "react-helmet";

function AboutUs() {
  const teamRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#team" && teamRef.current) {
      teamRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="about-us-container">
      {/* seo on aboutus page */}
      <Helmet>
        <title>About Us | Ardra AI</title>
        <meta
          name="description"
          content="Learn about Ardra AI, a cutting-edge SaaS platform transforming the energy sector with sustainable solutions. Partner with us to pioneer advancements in energy management."
        />
        <meta
          name="keywords"
          content="Ardra AI, Energy Management, Sustainable Energy, SaaS Platform, Upekkha Accelerator, AI-powered Energy Solutions, Green Energy, Energy Efficiency"
        />
        <meta property="og:title" content="About Us | Ardra AI" />
        <meta
          property="og:description"
          content="Ardra AI is committed to transforming the energy sector and driving progress for a cleaner, greener future. Join us on our journey."
        />
        <meta property="og:image" content={dataimage} />
        <meta property="og:url" content="https://ardra.ai/aboutUs" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Ardra AI" />
        <meta
          name="twitter:description"
          content="Join the future of energy with Ardra AI. Together, we can lead the charge towards a sustainable and efficient energy landscape."
        />
        <meta name="twitter:image" content={dataimage} />
      </Helmet>

      <div className="circle-top"></div>
      <header className="about-us-header">
        <div className="intro-section">
          <p className="intro-text"> About Us</p>
          <p className="intro-passage-text">
            Ardra AI started in 2023 as a project born to transform outdated
            data pipelines in the energy sector. By November 2023, we
            successfully launched a cutting-edge SaaS platform tailored for US
            and Indian facilities and utilities. We marked our entry into the
            Upekkha Accelerator's winter batch for US market penetration. Today,
            we continue to pioneer transformative solutions and are thrilled to
            share our journey with you.
          </p>
        </div>
      </header>

      <div className="about-section">
        <h3 className="about-title">
          Empowering Sustainable Energy: Partner with Ardra AI
        </h3>
        <p className="about-paragraph">
          Join the future of energy with Ardra AI, your partner in sustainable
          innovation. Together, we can lead the charge towards an Aware, AI
          powered, Sustainable and Efficient energy landscape. At Ardra AI we
          are committed to transforming the energy sector and driving progress
          for a cleaner, greener future. Partner with us to pioneer advancements
          in sustainable energy management and make a lasting impact on the
          world.
        </p>
      </div>

      <p className="highlight-p">The Ardra Story</p>

      <div className="highlight-section">
        <p className="highlight-text">
          <i className="fa-solid fa-quote-left fa-lg fa-fade"></i>
          &nbsp;&nbsp;Buildings consume 40% of the world's energy - Just as
          fitness bands track our physical well-being, we need advanced tools to
          monitor the "health" of our buildings, facilities, and refineries.
          &nbsp;&nbsp;
          <i className="fa-solid fa-quote-right fa-lg fa-fade"></i>
        </p>
      </div>

      <section className="our-vision-section">
        <div className="vision-image">
          <img src={dataimage} alt="Data Analytics" />
        </div>
        <div className="vision-content">
          <h3 className="section-title">Our Vision</h3>
          <p className="section-text">
            We envision a future where every facility, from SMEs to large
            industrial complexes, can effortlessly manage their energy
            consumption, make informed decisions, and contribute to a greener
            planet. Our commitment is to continually innovate and provide
            cutting-edge solutions that drive both economic and environmental
            benefits, positioning <strong>ardra.ai</strong> as a leader in the
            global energy management landscape.
          </p>
        </div>
      </section>

      <section className="team-section" id="Team" ref={teamRef}>
        <Team />
      </section>
    </div>
  );
}

export default AboutUs;
