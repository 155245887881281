import React, { useEffect } from "react";
import "./Product.css";
import emsImage from "../Assist/landingfeatureassist/ems2.png";
import probeImage from "../Assist/landingfeatureassist/probelan3.jpeg";
import gptImage from "../Assist/landingfeatureassist/gptlan.jpeg";

function SmallDeviseMenu() {
  useEffect(() => {
    const handleScroll = () => {
      const products = document.querySelectorAll(".product");
      const windowHeight = window.innerHeight;

      products.forEach((product, index) => {
        const productPosition = product.getBoundingClientRect().top;
        if (productPosition < windowHeight) {
          product.classList.add("visible");
          product.style.animationDelay = `${index * 0.5}s`;
        } else {
          product.classList.remove("visible");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="side-content">
      <div className="top-section">
        <h1 className="top-heading">Optimize Your Energy Management</h1>
        <p className="top-subheading">
          Unlock the potential of data-driven solutions for efficient and
          cost-effective energy management.
        </p>
      </div>

      <div className="product-container">
        <div className="product">
          <div className="product-text">
            <img src={emsImage} alt="ardraEMS" className="product-image" />
            <h2 className="product-name">ardraEMS</h2>

            <p>
              Empower Your Facility with Ardra AI's Comprehensive Energy
              Management System.
            </p>
            <a href="/emsPage" className="explore-link" aria-label="Explore">
              Explore &gt;
            </a>
          </div>
        </div>

        <div className="product reverse">
          <div className="product-text">
            <img src={probeImage} alt="ardraProbe" className="product-image" />
            <h2 className="product-name">ardraPROBE</h2>
            <p>
              Streamline Your Data Management with PROBE. Seamlessly integrate
              data from multiple sources, ensure data accuracy.
            </p>
            <a href="/probe" className="explore-link" aria-label="Explore">
              Explore &gt;
            </a>
          </div>
        </div>

        <div className="product">
          <div className="product-text">
            <img
              src={gptImage}
              alt="ardraGPT"
              className="product-image gptimage"
            />
            <h2 className="product-name">ardraGPT</h2>
            <p>Unlock Intelligent Energy Insights with ardraGPT.</p>
            <a
              href="/energyGPTPage"
              className="explore-link"
              aria-label="Explore"
            >
              Explore &gt;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallDeviseMenu;
