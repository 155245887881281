import React from "react";
import "./Partners.css";
import partner from "../Assist/partners/partner.png";

const Partners = () => {
  return (
    <div className="partner-container">
      <div className="partner-content">
        <div className="partner-logo">
          <img src={partner} alt="Partner Logo" />
        </div>
        <div className="partner-details">
          <h2>Our Partner</h2>
          <p>
            The Upekkha Accelerator Program is a crucial partner for us,
            providing essential funding and support to propel our product
            forward.
          </p>
          {/* <a href="https://example.com" className="learn-more">Learn More</a> Update with your actual link */}
        </div>
      </div>
    </div>
  );
};

export default Partners;
