import React from "react";
import "./feature.css";
import Lottie from "react-lottie";
import animationData from "../Assist/landingfeatureassist/sign.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import keyimage from "../Assist/landingfeatureassist/keyfeature.jpeg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const features = [
  {
    title: "Real-Time Monitoring and Control",
    description:
      "Monitor and manage energy usage, machinery performance, and environmental conditions.",
    icon: "fas fa-tachometer-alt",
  },
  {
    title: "Advanced Data Analytics",
    description:
      "Real-time data visualization, historical trend analysis, and AI-driven predictive insights.",
    icon: "fas fa-chart-line",
  },
  {
    title: "Seamless Data Integration",
    description:
      "Integrate data from IoT devices, SQL databases, REST APIs, and various file formats.",
    icon: "fas fa-database",
  },
  {
    title: "Customizable Alarms and Alerts",
    description:
      "Set up alarms for critical events and receive alerts for equipment malfunctions.",
    icon: "fas fa-bell",
  },
  {
    title: "Comprehensive Reporting",
    description:
      "Generate custom reports and visualize complex data with interactive graphs.",
    icon: "fas fa-file-alt",
  },
  {
    title: "Remote Assist and Management",
    description:
      "Manage and control your systems remotely with AI on the Edge.",
    icon: "fas fa-tools",
  },
];

const Feature = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="feature-carousel pb-5 pt-5">
      <div className="row feature-intro-section">
        <div className="col-md-9">
          <h5 className="features-title">
            Discover the Power of Ardra AI: Key Features
          </h5>
          <p className="features-intro">
            Explore the innovative features that make Ardra AI the ultimate
            solution for <br /> advanced energy management.
          </p>
        </div>
        <div className="col-md-3 text-md-right learn-more-feature">
          <div className="lottie-container">
            <img src={keyimage} alt="Key Feature" />
          </div>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={30}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        breakpoints={{
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          0: {
            slidesPerView: 1,
          },
        }}
        className="mySwiper"
      >
        {features.map((feature, index) => (
          <SwiperSlide key={index}>
            <div className="feature-wrap">
              <div className="feature-icon">
                <i className={feature.icon}></i>
              </div>
              <h4>{feature.title}</h4>
              <p>{feature.description}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Feature;
