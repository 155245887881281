import React from "react";
import "./EnergyGPTPage.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Helmet } from "react-helmet";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const features = [
  {
    icon: <i className="fas fa-search"></i>,
    title: "Advanced Data Queries",
    description:
      "ardraGPT leverages state-of-the-art AI and natural language processing to enable sophisticated queries about your energy usage, delivering precise insights.",
  },
  {
    icon: <i className="fas fa-clock"></i>,
    title: "Real-Time Analytics",
    description:
      "Access real-time analytics of your energy data. ardraGPT processes live data to provide immediate insights, facilitating prompt decisions and optimizations.",
  },
  {
    icon: <i className="fas fa-chart-bar"></i>,
    title: "Custom Reporting",
    description:
      "Generate tailored reports with ardraGPT, customized to specific timeframes, equipment, or areas within your facility, ensuring comprehensive insights.",
  },
  {
    icon: <i className="fas fa-chart-line"></i>,
    title: "Predictive Analytics",
    description:
      "Utilize predictive analytics capabilities in ardraGPT to forecast potential issues, empowering proactive energy management strategies.",
  },
];

const EnergyGPTPage = () => {
  return (
    <div className="energygpt-section">
      {/* seo on ardraGPT page */}
      <Helmet>
        <title>ardraGPT - AI-Powered Energy Management | Ardra AI</title>
        <meta
          name="description"
          content="Discover ardraGPT by Ardra AI - Advanced AI-driven energy management tool with real-time analytics, predictive insights, and custom reporting."
        />
        <meta
          name="keywords"
          content="AI Energy Management, ardraGPT, Energy Analytics, Predictive Analytics, Real-Time Energy Data"
        />
        <meta name="author" content="Ardra AI Team" />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="ardraGPT - AI-Powered Energy Management"
        />
        <meta
          property="og:description"
          content="Transform your energy management with ardraGPT - Real-time analytics, advanced data queries, and predictive insights."
        />
        <meta
          property="og:image"
          content="https://ardra.ai/static/media/navbarLogo.d0b559cb535acde4c603.png"
        />
        <meta property="og:url" content="https://ardra.ai/ardra-gpt" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="ardraGPT - AI-Powered Energy Management"
        />
        <meta
          name="twitter:description"
          content="Optimize your energy usage with ardraGPT - AI-driven insights for smarter energy management."
        />
        <meta
          name="twitter:image"
          content="https://ardra.ai/static/media/navbarLogo.d0b559cb535acde4c603.png"
        />
      </Helmet>
      <div className="energygpt-intro">
        <h2 className="energygpt-title">
          ardraGPT: Intelligent Energy Insights at Your Fingertips
        </h2>
        <p className="energygpt-description">
          Harness the Power of AI with ardraGPT
        </p>
      </div>
      <div className="energygpt-content">
        <p className="energygpt-text">
          Welcome to ardraGPT, the latest cutting-edge feature in Ardra AI’s
          suite of energy management solutions. ardraGPT is designed to empower
          facility managers, technicians, and building operators by providing
          instant, AI-driven insights into your energy data. With ardraGPT, you
          can query your energy data and receive detailed, actionable results,
          making energy management more intuitive and efficient than ever before
        </p>
        <hr />
        <div className="energygpt-features">
          <h3>Key Capabilities of ardraGPT</h3>
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 10 },
              640: { slidesPerView: 2, spaceBetween: 20 },
              1024: { slidesPerView: 3, spaceBetween: 30 },
            }}
            className="mySwiper"
          >
            {features.map((feature, index) => (
              <SwiperSlide key={index}>
                <div className="energyGPT-feature-wrap">
                  <div className="energyGPT-icon">{feature.icon}</div>
                  <h4 className="energyGPT-feature-title">{feature.title}</h4>
                  <p className="energyGPT-feature-description">
                    {feature.description}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="energygpt-how-it-works">
          <h3>How ardraGPT Works:</h3>
          <div className="energygpt-how-it-works-content">
            <div style={{ backgroundColor: "rgb(143, 163, 255)" }}>
              <i className="fas fa-question-circle"></i>
              <p>
                Submit Queries: Simply input your energy-related queries in
                plain language.
              </p>
            </div>
            <div style={{ backgroundColor: "rgb(209, 230, 209)" }}>
              <i className="fas fa-brain"></i>
              <p>
                Receive Immediate Insights: ardraGPT processes your queries
                swiftly, providing accurate and actionable insights.
              </p>
            </div>
            <div style={{ backgroundColor: "#c683ce" }}>
              <i className="fas fa-tools"></i>
              <p>
                Implement Recommendations: Act upon the insights offered by
                ardraGPT to optimize your energy management practices.
              </p>
            </div>
          </div>
        </div>
        <div className="energygpt-footer">
          <h3>Experience the Future of Energy Management with ardraGPT</h3>
          <p>
            ardraGPT is revolutionizing energy management with advanced AI
            capabilities. Enhance operational efficiency, optimize energy
            consumption, and make informed decisions effortlessly.
          </p>
          <a
            href="https://calendly.com/pranjal-2diz/ardra-ai-socials"
            target="_blank"
          >
            <button className="energygpt-demo-button">Book a Demo</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default EnergyGPTPage;
