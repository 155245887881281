import React from "react";
import Lottie from "lottie-react";
import animationData from "../Assist/landingfeatureassist/Circle.json";

import "./styles.css";

function Home_Header() {
  return (
    <>
      <header className="home-header-container">
        <div className="overlay"></div>
        <div className="content">
          <div className="text-content">
            <h1 className="main-title">Gen AI-Based Monitoring System</h1>
            <p className="sub-title">
              Improve Productivity up to 2X and Sustainable Energy Saving up to
              25%
            </p>
            <div className="buttons">
              <a href="http://app.ardracloud.com">
                <button className="cta-button1">Get Started</button>
              </a>
              <a
                href="https://calendly.com/pranjal-2diz/ardra-ai-socials?month=2024-08"
                className="cta-link"
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="animation-content">
            <Lottie
              className="lottie-circle"
              id="circle"
              animationData={animationData}
              loop={true}
            />
          </div>
        </div>
        <div className="background-animation"></div>
      </header>

      <section className="video-section">
        <div
          className="video-container"
          style={{ padding: "0 20px", marginTop: "2%" }}
        >
          <iframe
            id="youtube-video"
            width="100%"
            height="500px"
            src="https://www.youtube.com/embed/2He1QOWatxA?autoplay=1&loop=1&playlist=2He1QOWatxA&rel=0"
            title="YouTube video"
            style={{ border: "none", borderRadius: "15px" }}
            allow="autoplay;"
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default Home_Header;
