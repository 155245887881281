import React from "react";
import "./Model.css";

const ModalForm = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleSubmit = event => {
    event.preventDefault();
    // Handle form submission logic here
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2>Talk to a Generative AI Expert</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input type="text" placeholder="First name*" required />
            <input type="text" placeholder="Last name*" required />
          </div>
          <div className="form-row">
            <input type="text" placeholder="Company name*" required />
            <input type="text" placeholder="Job title*" required />
          </div>
          <input
            type="email"
            className="modelEmail"
            placeholder="Work email*"
            required
          />
          <div className="form-group">
            <p>Project Budget*</p>
            <label>
              <input type="radio" name="budget" value="<50k" required />$2k-$5k
            </label>
            <label>
              <input type="radio" name="budget" value="50-250k" required />{" "}
              $5k-$10k
            </label>
            <label>
              <input type="radio" name="budget" value="250k-1m" required />{" "}
              $10k-$15k
            </label>
            <label>
              <input type="radio" name="budget" value="1m+" required /> $15+
            </label>
          </div>
          <textarea
            placeholder="Please provide details on your project.*"
            required
          ></textarea>
          <div className="form-group">
            <label>
              <input type="checkbox" required /> I agree to receive
              communications from Ardra AI
            </label>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ModalForm;
