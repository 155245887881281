import React from 'react';
import './comparison.css';

const ComparisonTable = () => {
    return (
        <div className="table-container">
            <h3>Feature Comparison</h3>
            <h1>Competitive Advantage and Analysis</h1>
            <p>ArdraAI vs. Competitors</p>
            <table>
                <thead>
                    <tr>
                        <th>Features</th>
                        <th>ArdraAI</th>
                        <th>Blackberry</th>
                        <th>Aliste</th>
                        <th>Other EMS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Hardware backed</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>Yes</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Software as a Service</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td>Control</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Developer Support</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Secured</td>
                        <td>Yes</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>Gen AI Reporting</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                    <tr>
                        <td>AI Based EDR</td>
                        <td>Yes</td>
                        <td>No</td>
                        <td>No</td>
                        <td>No</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ComparisonTable;
