import React from "react";
import "./CareerPage.css";
import { Helmet } from "react-helmet";

function Career() {
  return (
    <div className="career-page">
      <Helmet>
        <title>Careers at Ardra AI | Join Our Team</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Ardra AI. Join a dynamic, forward-thinking team passionate about sustainability and innovation. Apply now!"
        />
        <meta
          name="keywords"
          content="Ardra AI careers, jobs at Ardra AI, sustainability jobs, innovation careers, tech careers, energy management jobs, join Ardra AI, Ardra AI internships"
        />
        <meta property="og:title" content="Careers at Ardra AI" />
        <meta
          property="og:description"
          content="Are you passionate about sustainability and innovation? Join Ardra AI and be part of a team that values creativity, collaboration, and continuous learning."
        />
        <meta property="og:image" content="URL_to_CareerPage_image" />
        <meta property="og:url" content="https://ardra.ai/career" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Careers at Ardra AI | Join Our Team"
        />
        <meta
          name="twitter:description"
          content="Explore exciting career opportunities at Ardra AI. Join a dynamic, forward-thinking team passionate about sustainability and innovation. Apply now!"
        />
        <meta name="twitter:image" content="URL_to_CareerPage_image" />
      </Helmet>

      <div className="career-content">
        <h2 className="career-title">Careers</h2>
        <p className="career-subtitle">Elevate your career with ArdraAI</p>
        <span className="career-description">
          Are you passionate about sustainability and innovation? When you join
          our team, you'll be part of a dynamic, forward-thinking company that
          values creativity, collaboration, and continuous learning.
        </span>
        <button className="career-button">Connect with us</button>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeBkhTAESEnr_LVkdQske7_H5VXjzIGNEYOVgEqwc-imeUlww/viewform?usp=sf_link"
          className="career-link"
        >
          Looking for internships
        </a>
      </div>
    </div>
  );
}

export default Career;
