import React, { useState } from "react";
import "./Faq.css";
const faqs = [
  {
    question: "What is Ardra AI?",
    answer:
      "Ardra AI is a cutting-edge energy management system designed to help facilities optimize their energy consumption. Our platform leverages AI and machine learning to provide insights, monitor energy usage, and offer actionable recommendations to reduce costs and enhance efficiency.",
  },
  {
    question: "What features does Ardra AI offer?",
    answer: (
      <ul className="custom-list">
        <li>Real-time energy monitoring</li>
        <li>Predictive analytics and reporting</li>
        <li>Integration with various IoT devices</li>
        <li>Alarm and alert systems</li>
        <li>Customizable dashboards</li>
        <li>Remote equipment management</li>
        <li>Data visualization tools</li>
      </ul>
    ),
  },
  {
    question: "What is EnergyGPT?",
    answer:
      "EnergyGPT is a new feature of Ardra AI that allows users to query their energy data and receive generated results. This tool leverages AI to provide detailed insights and recommendations based on the data queried.",
  },
  {
    question: "How does Ardra AI integrate with existing systems?",
    answer:
      "Ardra AI is designed to be highly compatible with existing infrastructure. It supports multiple communication protocols, allowing seamless integration with both modern and legacy systems. Our proprietary ArdraSYNC protocol ensures smooth data ingestion and synchronization.",
  },
  {
    question: "What types of facilities can benefit from Ardra AI?",
    answer:
      "Ardra AI is suitable for a wide range of facilities including commercial buildings, manufacturing plants, educational institutions, and more. Any facility looking to optimize energy usage and reduce costs can benefit from our system.",
  },
  {
    question: "What are Ardra AI's industry-standard AI agents?",
    answer:
      "Ardra AI has developed industry-standard AI agents specifically for the energy industry. These agents enhance energy management processes by providing intelligent automation, advanced analytics, and predictive maintenance capabilities.",
  },
  {
    question: "How does PROBE work in Ardra AI?",
    answer:
      "PROBE is a feature in Ardra AI that facilitates data ingestion and visualization. It supports data from IoT hardware sources, SQL databases, REST APIs, JSON files, PDFs, and more. PROBE uses ArdraSYNC for seamless data integration and provides real-time dashboards for data management.",
  },
  {
    question: "What is Ardra ONE and how does it work?",
    answer:
      "Ardra ONE is our edge hardware designed to sync data from various assets, equipment, and machines to Ardra AI PROBE. It features an ARM controller, supports WiFi/BLE/4G SIM cards, and LoRaWAN if required. It also supports multiple wired network ports and communication protocols, making it versatile for different applications.",
  },
  {
    question: "Can Ardra AI be accessed remotely?",
    answer:
      "Yes, Ardra AI provides remote access capabilities. Users can monitor and control their facilities from anywhere using our web dashboard. This feature ensures that you can manage your energy usage and respond to alerts even when you're offsite.",
  },
  {
    question: "How secure is the data in Ardra AI?",
    answer:
      "Data security is a top priority at Ardra AI. We use robust encryption protocols to protect data during transmission and storage. Our platform is designed to comply with industry standards for data security and privacy.",
  },
  {
    question: "How do I get started with Ardra AI?",
    answer:
      "Getting started with Ardra AI is simple. You can sign up on our website to request a demo or consultation. Our team will work with you to understand your needs, set up the system, and ensure it is configured to optimize your facility's energy usage.",
  },
  {
    question: "What kind of support does Ardra AI offer?",
    answer:
      "Ardra AI offers comprehensive support including installation assistance, training, and ongoing technical support. Our team is available to help you make the most of our platform and address any issues that may arise.",
  },
  {
    question: "How can Ardra AI help in reducing energy costs?",
    answer:
      "Ardra AI helps reduce energy costs by providing detailed insights into energy consumption patterns, identifying inefficiencies, and recommending actionable steps to optimize usage. Our predictive analytics feature also helps prevent costly downtime and maintenance issues.",
  },
  {
    question: "Can Ardra AI be customized to fit specific needs?",
    answer:
      "Yes, Ardra AI is highly customizable. Users can configure dashboards, set up custom alerts, and tailor the system to meet specific operational needs. Our team also offers bespoke solutions for unique requirements.",
  },
  {
    question: "Is Ardra AI scalable?",
    answer:
      "Absolutely. Ardra AI is designed to scale with your business. Whether you are managing a single facility or multiple locations, our platform can handle the complexity and volume of data, providing consistent performance and insights.",
  },
  {
    question: "What sets Ardra AI apart from other energy management systems?",
    answer:
      "Ardra AI stands out due to its advanced AI capabilities, seamless integration with existing systems, real-time data visualization, and comprehensive support. Our focus on user-friendly interfaces and actionable insights ensures that users can easily optimize their energy usage and reduce costs.",
  },
  {
    question: "What are the pricing plans for Ardra AI?",
    answer:
      "Ardra AI offers flexible pricing plans to accommodate different needs and budgets. We provide a range of packages based on the size of your facility and the features you require. For detailed pricing information, please contact our sales team or visit our website.",
  },
  {
    question: "Is there a free trial available for Ardra AI?",
    answer:
      "Yes, Ardra AI offers a free trial period for new users to experience the platform and its features. During the trial, you can explore the system's capabilities and determine how it fits your energy management needs. To sign up for a free trial, visit our website.",
  },
  {
    question: "Are there any additional costs for hardware?",
    answer:
      "The cost of hardware, such as Ardra ONE, is typically included in our pricing packages. However, for larger installations or specialized hardware requirements, there may be additional costs. Our sales team can provide detailed information based on your specific needs.",
  },
  {
    question: "What payment methods does Ardra AI accept?",
    answer:
      "Ardra AI accepts various payment methods including credit cards, bank transfers, and corporate invoicing. Our billing team can work with you to ensure a smooth payment process.",
  },
  {
    question: "Are there any discounts available for long-term contracts?",
    answer:
      "Yes, Ardra AI offers discounts for long-term contracts and bulk purchases. Contact our sales team to discuss your requirements and learn more about available discount options.",
  },
];
function Faq() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleAllFaqs = () => {
    setIsExpanded(!isExpanded);
    setActiveIndex(isExpanded ? null : 0); // Set to 0 if expanded to collapse all
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqs.slice(0, 8).map((faq, index) => (
        <div className="faq" key={index}>
          <div className="faq-question" onClick={() => toggleFaq(index)}>
            <span>{faq.question}</span>
            <span className="faq-toggle">
              <i
                className={
                  activeIndex === index ? "fas fa-minus" : "fas fa-plus"
                }
              ></i>
            </span>
          </div>
          <div
            className={`faq-answer ${activeIndex === index ? "expanded" : ""}`}
          >
            {faq.answer}
          </div>
        </div>
      ))}
      <button className="show-more-button" onClick={toggleAllFaqs}>
        <span className="show-more-text">
          {isExpanded ? "Show less" : "Have more questions?"}
        </span>
        <span className="click-here">{isExpanded ? "" : " Click here"}</span>
        <i
          className={`fas ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"} icon`}
        ></i>
      </button>
      {isExpanded &&
        faqs.slice(8).map((faq, index) => (
          <div className="faq" key={index + 8}>
            <div className="faq-question" onClick={() => toggleFaq(index + 8)}>
              <span>{faq.question}</span>
              <span className="faq-toggle">
                <i
                  className={
                    activeIndex === index + 8 ? "fas fa-minus" : "fas fa-plus"
                  }
                ></i>
              </span>
            </div>
            <div
              className={`faq-answer ${activeIndex === index + 8 ? "expanded" : ""}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Faq;
