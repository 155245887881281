import React, { useRef, useEffect } from "react";
import "./Home.css";

import Feature from "../Component/Feature";
import UseCases from "../Component/UseCases";
import Partners from "../Component/Partners";
import Home_Header from "../Component/Home_Header";
import SignInButton from "../Component/SignIn";
import SmallDeviseMenu from "../Component/Product";
import Faq from "../Component/Faq";
import Teatimonial from "../Component/Testimonials/testimonial";
import ComparisonTable from "../Component/ComparisonTable/comparison";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home() {
  const faqRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#faq" && faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="home-container">
      <Helmet>
        <title>Gen AI-Based Monitoring System - Ardra AI</title>
        <meta
          name="description"
          content="Improve productivity up to 2X and sustainable energy savings up to 25% with Ardra AI's Gen AI-Based Monitoring System."
        />
        <meta
          name="keywords"
          content="AI, Monitoring System, Energy Saving, Productivity, Sustainable Energy, Ardra AI, Energy Trading, Predictive Maintenance"
        />
        <meta
          property="og:title"
          content="Gen AI-Based Monitoring System - Ardra AI"
        />
        <meta
          property="og:description"
          content="Enhance your operations with AI-driven monitoring. Boost productivity and save energy."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ardra.ai/" />
        <meta
          property="og:image"
          content="https://ardra.ai/static/media/navbarLogo.d0b559cb535acde4c603.png"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ardra.ai/" />
      </Helmet>
      <Home_Header />

      <div className="product-section">
        <SmallDeviseMenu />
      </div>

      <div className="comparison-section">
        <ComparisonTable />
      </div>

      <div className="feature-section">
        <Feature />
      </div>

      <div className="usecase-section">
        <UseCases />
      </div>

      <div className="signIn-section">
        <SignInButton />
      </div>

      <div className="testimonial-section">
        <Teatimonial />
      </div>

      <div className="partner-section">
        <Partners />
      </div>

      {/* FAQs section */}
      <div className="Faq-section" ref={faqRef}>
        <Faq />
      </div>
    </div>
  );
}
