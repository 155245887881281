import React from "react";
import "./Blog.css";

const Blog = () => {
  return (
    <div className="blog-container">
      <div className="blog-header">
        <h2>Latest Blog Posts</h2>
        <p>Explore insights and updates from our team.</p>
      </div>
      <div className="blog-list">
        <div className="blog-item">
          <div className="blog-image">
            <img
              src="https://jammydigital.com/wp-content/uploads/2021/10/content-templates-documents.png"
              alt="Blog Post 1"
            />
            <div className="blog-overlay">
              <h3>Post Title 1</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="blog-item">
          <div className="blog-image">
            <img
              src="https://jammydigital.com/wp-content/uploads/2021/10/content-templates-documents.png"
              alt="Blog Post 2"
            />
            <div className="blog-overlay">
              <h3>Post Title 2</h3>
              <p>
                Phasellus vitae felis dictum, maximus leo a, suscipit libero.
              </p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
        </div>
        <div className="blog-item">
          <div className="blog-image">
            <img
              src="https://jammydigital.com/wp-content/uploads/2021/10/content-templates-documents.png"
              alt="Blog Post 3"
            />
            <div className="blog-overlay">
              <h3>Post Title 3</h3>
              <p>Vivamus aliquet dolor sit amet ligula condimentum.</p>
              <a href="#" className="read-more">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
