import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";
import Layout from "./Component/Layout";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import AboutUs from "./Pages/AboutUs";
import Career from "./Pages/Career";
import Document from "./Component/Document";
import Partners from "./Component/Partners";
import Blog from "./Component/Blog";
import EmsPage from "./Pages/EmsPage";
import ProbePage from "./Pages/ProbePage";
import EnergyGPTPage from "./Pages/EnergyGPTPage";
import Team from "./Component/Team";
import Faq from "./Component/Faq";
import Testimonials from "./Component/Testimonials/testimonial";
import ComparisonTable from "./Component/ComparisonTable/comparison";
import ScrollToTop from "./Component/ScrollToTop";

// Google Analytics initialization
ReactGA.initialize("G-V5MB7S9LGD");

const AnalyticsTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AnalyticsTracker /> {/* Add AnalyticsTracker here */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="career" element={<Career />} />
          <Route path="document" element={<Document />} />
          <Route path="partner" element={<Partners />} />
          <Route path="emsPage" element={<EmsPage />} />
          <Route path="energyGPTPage" element={<EnergyGPTPage />} />
          <Route path="probe" element={<ProbePage />} />
          <Route path="blog" element={<Blog />} />
          <Route path="team" element={<Team />} />
          <Route path="faq" element={<Faq />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="comparison" element={<ComparisonTable />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
