import React from "react";
import "./testimonial.css";

const testimonials = [
  {
    name: "Ashish Sharma",
    title: "Sr Manager, Facility Management",
    feedback:
      "“Ardra AI Energy management system helped us to reduce monthly energy bill of our retail outlet chain we manage”",
  },
  {
    name: "Rajesh B. Desai",
    title: "Director, SSSIHMS, Andhra Pradesh",
    feedback:
      "“Ardra AI helps us to monitor energy consumption in our university, Ardra AI team is very professional and dedicated”",
  },
];

function Testimonials() {
  return (
    <div className="testimonials-section">
      <h2 className="testimonials-heading">Testimonials</h2>
      <div className="testimonials-carousel">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-feedback">{testimonial.feedback}</div>
            <div className="testimonial-author">
              <h3>{testimonial.name}⭐⭐⭐⭐⭐</h3>
              <p>{testimonial.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonials;
