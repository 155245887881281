import React, { useState } from "react";
import "./Document.css";

function Document() {
  const [activeSection, setActiveSection] = useState("QuickStart");

  const handleSectionClick = section => {
    setActiveSection(section);
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="doc-container">
      <div className="doc-sidebar">
        <ul>
          <li
            onClick={() => handleSectionClick("QuickStart")}
            className={activeSection === "QuickStart" ? "active" : ""}
          >
            Quick Start
          </li>
          <li
            onClick={() => handleSectionClick("UserGuide")}
            className={activeSection === "UserGuide" ? "active" : ""}
          >
            User Guide
            <ul>
              <li
                onClick={() => handleSectionClick("AboutProduct")}
                className={activeSection === "AboutProduct" ? "active" : ""}
              >
                About Product
              </li>
              <li
                onClick={() => handleSectionClick("Integration")}
                className={activeSection === "Integration" ? "active" : ""}
              >
                Integration
              </li>
              <li
                onClick={() => handleSectionClick("Security")}
                className={activeSection === "Security" ? "active" : ""}
              >
                Security
              </li>
            </ul>
          </li>
          <li
            onClick={() => handleSectionClick("APIReference")}
            className={activeSection === "APIReference" ? "active" : ""}
          >
            API Reference
          </li>
          <li
            onClick={() => handleSectionClick("Resources")}
            className={activeSection === "Resources" ? "active" : ""}
          >
            Resources
          </li>
        </ul>
      </div>
      <div className="doc-content">
        <section id="QuickStart" className="doc-section">
          <h2>Quick Start</h2>
          <p>
            Welcome to Ardra AI! Get started with our energy management solution
            by following these steps:
          </p>
          <ol>
            <li>Create your Ardra AI account by signing up on our website.</li>
            <li>
              Connect your energy data sources, such as IoT devices, databases,
              and APIs.
            </li>
            <li>
              Set up your dashboard to start visualizing and analyzing your
              energy data.
            </li>
            <li>
              Explore the features and capabilities of Ardra AI to optimize your
              energy management.
            </li>
          </ol>
        </section>
        <section id="UserGuide" className="doc-section">
          <h2>User Guide</h2>
          <section id="AboutProduct">
            <h3>About Product</h3>
            <p>
              Ardra AI is a comprehensive energy management solution that offers
              advanced data ingestion, visualization, and analysis capabilities.
              Our key features include:
            </p>
            <ul>
              <li>
                <strong>EnergyGPT:</strong> Provides AI-driven insights for
                intelligent energy management.
              </li>
              <li>
                <strong>Probe:</strong> Facilitates advanced data ingestion and
                visualization.
              </li>
              <li>
                <strong>Energy Management System (EMS):</strong> Integrates
                energy data for holistic management.
              </li>
            </ul>
          </section>
          <section id="Integration">
            <h3>Integration</h3>
            <p>
              Integrate Ardra AI seamlessly with your existing systems. Follow
              these steps for integration:
            </p>
            <ol>
              <li>Connect IoT devices to gather real-time energy data.</li>
              <li>
                Use our APIs to link Ardra AI with your databases and other
                systems.
              </li>
              <li>
                Customize your dashboards to include data from various sources.
              </li>
            </ol>
          </section>
          <section id="Security">
            <h3>Security</h3>
            <p>
              Ardra AI prioritizes the security of your data. Our security
              measures include:
            </p>
            <ul>
              <li>Encryption of data at rest and in transit.</li>
              <li>Regular security audits and updates.</li>
              <li>Access control mechanisms to ensure data privacy.</li>
            </ul>
          </section>
        </section>
        <section id="APIReference" className="doc-section">
          <h2>API Reference</h2>
          <p>
            Our APIs allow you to access and manipulate your energy data
            programmatically. Key API endpoints include:
          </p>
          <ul>
            <li>
              <code>/api/data</code>: Retrieve your energy data.
            </li>
            <li>
              <code>/api/reports</code>: Generate and access customized reports.
            </li>
            <li>
              <code>/api/alerts</code>: Set up and manage alerts for specific
              energy events.
            </li>
          </ul>
        </section>
        <section id="Resources" className="doc-section">
          <h2>Resources</h2>
          <p>
            Explore additional resources to maximize the benefits of Ardra AI:
          </p>
          <ul>
            <li>
              <a href="#">Documentation</a>: Comprehensive guides and
              references.
            </li>
            <li>
              <a href="#">Community Forum</a>: Connect with other Ardra AI
              users.
            </li>
            <li>
              <a href="#">Support</a>: Get help from our support team.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default Document;
