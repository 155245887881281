import React, { useState } from "react";
import "./SignIn.css";
import ModalForm from "./Model/Model.js";

const SignInButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="signin-section">
      <div className="signin-content">
        <div className="intro-section">
          <h2 className="intro-heading">Unlock Your Energy Insights</h2>
          <p className="intro-paragraph">
            With Ardra AI, you gain access to cutting-edge energy analytics,
            real-time data, and advanced optimization tools. Talk to Our Expert
            to leverage our AI-driven solutions and transform your Facility.
          </p>
        </div>
        <div className="signin-row">
          <div className="button-container">
            <button className="signin-btn" onClick={openModal}>
              <span>
                Talk to a Generative AI Expert{" "}
                <i class="fa-solid fa-angles-right fa-beat-fade"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <ModalForm isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default SignInButton;
